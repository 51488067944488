import * as React from 'react'
import * as styles from './aboutSection.module.css'
import { StaticImage } from 'gatsby-plugin-image'

function AboutSection(props) {
	return (
		<div className={styles.aboutSection}>
			<StaticImage 
				src='../img/mom-painting.jpg' 
				className={styles.aboutImgWrapper} 
				imgClassName={styles.aboutImg} 
				alt=''
			/>
			<span className={styles.aboutTextWrapper}>
				<h1 className={styles.aboutTextTitle}>Bethany Rachelle</h1>
				<p className={styles.aboutText}>Beth graduated from Michigan State University with an MA in Counseling and Psychology.  She worked corporately, owned her own businesses, traveled and raised her two sons — always finding her way back to art.  She has owned an art gallery, taught lessons, founded and coordinated fine arts festivals, and served on various community arts boards.  Her work has been exhibited in galleries, museums, and at shows throughout the southeast; and, can also be found in private collections throughout the US, Europe, and Australia.  She currently works from her studio in the North Georgia Mountains of Big Canoe.</p>
			</span>
		</div>
	)
}

export { AboutSection }