import * as React from 'react'
import { Layout } from '../components/layout'
import { AboutSection } from '../components/aboutSection'
import { Helmet } from 'react-helmet'

export default function AboutPage(props) {

	return (
		<Layout>
			<Helmet>
        	<title>About | Bethany Rachelle</title>
      	</Helmet>
			<AboutSection />
		</Layout>
	)
}